import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { Button, Select, Input, message } from 'antd';
import { useServices } from '../zustand/Services';

const { Option } = Select;

interface Props{
  info:any;
}

export function Contact(props:Props) {
  const services = useServices((state:any) => state.services).data;
  const serviceTypes = useServices((state:any) => state.serviceTypes);
  const form = useRef<any>();
  const { info } = props;

  const sendEmail = (e:any) => {
    e.preventDefault();

    emailjs
      .sendForm('service_4x1ctpd', 'template_230kk1e', form.current, {
        publicKey: 'OnaqJR0A76MiQjnAp',
      })
      .then(
        message.success('Đã gửi thông tin. Trân trọng cảm ơn quý khách!')
      )
  };

  const [packs, setPacks] = useState<string[]>([]);
  const [packsDisabled, setPacksDisabled] = useState<boolean>(true);

  const [userService, setUserService] = useState('');
  const [userPack, setUserPack] = useState('');

  const setPacksByService = (type:string) => {
    if(type){
      let result:any = services.filter((item:any) => item.type == type);
      setPacks(result);
      setPacksDisabled(false);
      setUserService(type);
    }
  }

  return (
    <section id="tuvanlapdat" className="mt-20">
      <div className="flex flex-col items-center justify-center">
          <div className="w-full md:w-[500px] bg-white rounded-lg shadow p-6 md:p-0 box-border">
              <div className="space-y-4 md:space-y-6 sm:p-8">
                  <h2 className="text-center text-xl font-bold leading-tight tracking-tight text-[#FF0000] md:text-2xl">
                      Đăng kí tư vấn lắp đặt
                  </h2>
                  <p className="text-justify">Cảm ơn quý khách hàng đã quan tâm dịch vụ, vui lòng điền phiếu đăng kí để được tư vấn hỗ trợ, hoặc gọi Hotline: {info.phone}</p>
                  <form className="space-y-4 md:space-y-6" ref={form} onSubmit={sendEmail}>
                      <div>
                          <Input type="email" name="user_email" placeholder="Email" required />
                      </div>
                      <div>
                          <Input type="text" name="user_name" placeholder="Họ và tên" required />
                      </div>
                      <div>
                          <Input type="tel" name="user_phone" pattern="[0]{1}[1-9]{1}[0-9]{8}" placeholder="Số điện thoại" required />
                      </div>
                      <div>
                          <Select
                            placeholder="Chọn dịch vụ"
                            allowClear
                            onChange={setPacksByService}
                            className="w-full uppercase"
                          >
                            {
                              serviceTypes.map((row:any) => {
                                return (
                                  <Option key={row.name} value={row.name} className="uppercase">{row.name}</Option>
                                )
                              })
                            }
                          </Select>
                      </div>
                      <div>
                          <Select
                            placeholder="Chọn gói cước"
                            allowClear
                            disabled={packsDisabled}
                            className="w-full"
                            onChange={(value) => {setUserPack(value)}}
                          >
                            {
                              packs.map((obj:any) => {
                                return (
                                  <Option key={obj.name} value={obj.name}><span className="uppercase">{obj.name}</span></Option>
                                )
                              })
                            }
                          </Select>
                      </div>
                      <input type="hidden" value={userService} name="user_service"/>
                      <input type="hidden" value={userPack} name="user_pack"/>
                      <Button htmlType="submit" type="primary">Gửi</Button>
                  </form>
              </div>
          </div>
      </div>
    </section>
  );
};
