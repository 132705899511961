import React, { useEffect, useState } from 'react';
import { ScrollToTop } from '../components/ScrollToTop';
import { Link } from 'react-router-dom';
import config from '../config.json';

const { serverhost } = config;

export function News() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    fetch(serverhost + '/news.php')
    .then(res => res.json())
    .then(data => {
      setNews(data);
    })
    .catch(err => console.log(err))
  })

  return (
    <div>
      <ScrollToTop/>

      <div className="mt-10">
        <h2 className="text-[#FF0000]">Tin tức - sự kiện</h2>
        <div className="space-y-4">
          {
            news.map((obj:any) => {
              return(
                <Link to={'/tintuc/' + obj.id} className="flex flex-col h-[200px] md:h-[150px] space-y-2 md:space-x-10 md:space-y-0 hover:bg-[#f2f2f2] p-4 md:flex-row">
                  <div className="h-full">
                    <img src={obj.image}/>
                  </div>
                  <div>
                    <div className="w-full text-[20px] text-[#000] font-bold">{obj.title}</div>
                    <div className="text-[#666666]">{obj.created_at}</div>
                  </div>
                </Link>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
