import React from 'react';
import { Price } from '../components/Price';
import { useServices } from '../zustand/Services';

interface Props{
  type: string
}

const isEmptyObj = (obj:any) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

export function Service(props:Props){
  const { type } = props;
  const servicesByType = useServices((state:any) => state.servicesByType);
  const service = servicesByType.find((item:any) => item.name == type);

  const event = service.event.split(/\r?\n|\r|\n/g);
  const content = service.content.split(/\r?\n|\r|\n/g);

  let totalPrice = 0;
  let totalPrice1 = 0;
  let totalPrice2 = 0;
  let totalSpeed = 0;
  service.data.map((obj:any) => {
    if(typeof obj.info == 'string'){
      obj.info = (obj.info) ? obj.info.split(/\r?\n|\r|\n/g) : [];
    }
    if(typeof obj.event == 'string'){
      obj.event = (obj.event) ? obj.event.split(/\r?\n|\r|\n/g): [];
    }
    totalPrice += parseFloat(obj.price);
    totalPrice1 += parseFloat(obj.price1);
    totalPrice2 += parseFloat(obj.price2);
    totalSpeed += parseFloat(obj.speed);
  })
  const isThead = [totalPrice, totalPrice1, totalPrice2, totalSpeed];

  if(isEmptyObj(service) == false){
    return(
      <div className="mt-10 space-y-10">
        <div>
          <h3>Bảng giá gói cước/thiết bị</h3>
          <div className="w-full overflow-x-scroll">
            <table className="w-full whitespace-nowrap" cellSpacing="0">
              <thead>
                <th align="left">Tên</th>
                {
                  (isThead[3] > 0) && (
                    <th align="center">Tốc độ</th>
                  )
                }
                {
                  (isThead[0] > 0) && (
                    <th align="center">Giá</th>
                  )
                }
                {
                  (isThead[1] > 0) && (
                    <th align="center">Giá nội thành</th>
                  )
                }
                {
                  (isThead[2] > 0) && (
                    <th align="center">Giá ngoại thành</th>
                  )
                }
                <th align="center">Ưu đãi</th>
              </thead>
              <tbody>
                {
                  (service.data.length > 0) && (
                    service.data.map((obj:any) => {
                      return (
                        <tr key={obj.id}>
                          <td align="left" className="w-[100px] min-w-[100px] uppercase font-bold">
                            {obj.name}
                          </td>
                          {
                            (isThead[3] > 0) && (
                              <td align="center">{obj.speed} Mbps</td>
                            )
                          }
                          {
                            (isThead[0] > 0) && (
                              <td align="center"><Price price={obj.price}/></td>
                            )
                          }
                          {
                            (isThead[1] > 0) && (
                              <td align="center"><Price price={obj.price1}/></td>
                            )
                          }
                          {
                            (isThead[2] > 0) && (
                              <td align="center"><Price price={obj.price2}/></td>
                            )
                          }
                          <td align="center" className="min-w-[300px]">
                            {
                              (obj.event.length > 0) ? (
                                obj.event.map((line:any) => {
                                  return(
                                    <div>{line}</div>
                                  )
                                })
                              ):(
                                <div className="center">-</div>
                              )
                            }
                          </td>
                        </tr>
                      )
                    })
                  )
                }
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <h3>Khuyễn mãi khi lắp đặt</h3>
          <div className="whitespace-normal text-justify leading-8">
            {
              (event.length > 0) ? (
                event.map((line:any) => {
                  return(
                    <div>{line}</div>
                  )
                })
              ):(
                <div>Khuyến mãi / ưu đãi đang được cập nhật...</div>
              )
            }
          </div>
        </div>
        <div>
          <h3>Thông tin thêm</h3>
          <div className="whitespace-normal text-justify leading-8">
            {
              (content.length > 0) ? (
                content.map((line:any) => {
                  return(
                    <div>{line}</div>
                  )
                })
              ):(
                <div>...</div>
              )
            }
          </div>
        </div>
      </div>
    )
  }
  else{
    return null;
  }
}
