import React from 'react';
import { Carousel } from 'antd';

interface Props {
  data: string;
}

export function TopCarousel(props:Props) {
  const { data } = props;
  let images:any = JSON.parse(data);

  if(images){
    return (
      <Carousel autoplay autoplaySpeed={2500}>
        {
          images.map((url:string, index:number) => {
            return(
              <div key={index}>
                <div className="w-full h-[30%]">
                  <img className="w-full h-full object-contain" src={url} />
                </div>
              </div>
            )
          })
        }
      </Carousel>
    );
  }
  else{
    return null;
  }
}
