import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Price } from '../components/Price';
import { Service } from '../components/Service';
import { Divider } from 'antd';
import { useServices } from '../zustand/Services';
import config from '../config.json';

const { serverhost } = config;

export function Home() {
  const serviceTypes = useServices((state:any) => state.serviceTypes);
  const servicesByType = useServices((state:any) => state.servicesByType);
  const [news, setNews] = useState([]);

  useEffect(() => {
    fetch(serverhost + '/news.php')
    .then(res => res.json())
    .then(data => {
      data = data.slice(0, 5);
      setNews(data);
    })
    .catch(err => console.log(err))
  }, [])

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 10
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  return (
    <div className="space-y-10">
      <div id="services" className="mt-10">
        <h2>dịch vụ của viettel hồ chí minh</h2>
        <div className="bg-[#e6f2ff] p-4 mb-10">
          <h3>Mục lục</h3>
          <ul>
            {
              serviceTypes.map((obj:any) => {
                return (
                  <li className="uppercase py-2"><a href={'#' + obj.id}>{obj.name}</a></li>
                )
              })
            }
          </ul>
        </div>
          {
            (servicesByType.length > 0) && (
              servicesByType.map((obj:any, index:number) => {
                return (
                  <div key={index} id={obj.id} className={"px-4 py-10 rounded-[6px] " + ((index%2==0) ? "bg-[#f2f2f2]":"")}>
                    <div><div className="font-bold uppercase text-[20px]">{obj.name}</div></div>
                    <div className="mt-2">{obj.description}</div>
                    <Carousel
                      infinite
                      swipeable
                      responsive={responsive}
                      className="h-fit mt-6 z-[500]"
                      partialVisible={false}
                      itemClass="flex justify-center"
                    >
                      {
                        obj.data.map((service:any, index:number) => {
                          return(
                            <div key={index}className="flex flex-col items-center w-[300px] h-[420px] bg-[#FFF] rounded-[10px] border-[1px] border-solid border-[#CCC]">
                              <div className="w-full h-[200px]"><img alt={service.name} src={service.image}/></div>
                              <div className="font-bold text-[20px] mt-4">
                                <span className="uppercase">{service.name}</span>{(service.speed > 0) ? (' / ' + service.speed + ' Mbps') : ''}
                              </div>
                              <Divider/>
                              {
                                (service.price > 0) && (
                                  <div className="flex justify-center font-bold text-[20px] w-[200px] h-[80px]">
                                    <Price price={service.price}/>
                                  </div>
                                )
                              }
                              {
                                (service.price1 + service.price2 + service.price3 > 0) && (
                                  <div className="w-[200px] h-[80px] space-y-2">
                                    {
                                      (service.price1 > 0) && (
                                        <div className="flex justify-between items-center"><span>Giá nội thành: </span><Price price={service.price1}/></div>
                                      )
                                    }
                                    {
                                      (service.price2 > 0) && (
                                        <div className="flex justify-between items-center"><span>Giá ngoại thành: </span><Price price={service.price2}/></div>
                                      )
                                    }
                                  </div>
                                )
                              }
                              <div>
                                <a href="#tuvanlapdat" className="bg-[#FF0000] p-2 rounded-[10px] text-[#FFF]">Đăng kí tư vấn</a>
                              </div>
                            </div>
                          )
                        })
                      }
                    </Carousel>
                    <Service type={obj.name}/>
                  </div>
                )
              })
            )
          }
      </div>
      <div>
        <h2 className="text-[#FF0000]">Tin tức - Sự kiện</h2>
        <div className="space-y-4">
          {
            news.map((obj:any) => {
              return(
                <Link key={obj.id} to={'/news/' + obj.id} className="flex flex-col h-[200px] md:h-[150px] space-y-2 md:space-x-10 md:space-y-0 hover:bg-[#f2f2f2] p-4 md:flex-row">
                  <div className="h-full">
                    <img src={obj.image}/>
                  </div>
                  <div className="space-y-2 md:space-y-10">
                    <div className="w-full text-[20px] text-[#000] font-bold">{obj.title}</div>
                    <div className="text-[#666666]">{obj.created_at}</div>
                  </div>
                </Link>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
