import React, { useState, useEffect } from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { FloatContactButton } from './components/FloatContactButton';
import { ScrollToTop } from './components/ScrollToTop';
import { Contact } from './components/Contact';
import { Home } from './pages/Home';
import { News } from './pages/News';
import { NewsDetail } from './pages/NewsDetail';
import { TopCarousel } from './components/TopCarousel';
import { useServices } from './zustand/Services';

import config from './config.json';

const { serverhost } = config;

function App() {
  const [info, setInfo] = useState<any>([]);
  const serviceTypes = useServices((state:any) => state.serviceTypes);
  const setServiceTypes = useServices((state:any) => state.setServiceTypes);
  const setServices = useServices((state:any) => state.setServices);
  const setServicesByType = useServices((state:any) => state.setServicesByType);

  useEffect(() => {
    fetch(serverhost + '/info.php')
    .then(res => res.json())
    .then(data => {
      setInfo(data);
    })
    .catch(err => console.log(err))

    fetch(serverhost + '/service-types.php')
    .then(res => res.json())
    .then(data => {
      setServiceTypes(data);
    })
    .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    fetch(serverhost + '/services.php')
    .then(res => res.json())
    .then(data => {
      setServices(data);
      let services:any = [];
      serviceTypes.map((obj:any, index:number) => {
        services[index] = {};
        services[index] = obj;
        services[index].data = data.data.filter((item:any) => item.type == obj['name']);
      })
      setServicesByType(services);
    })
    .catch(err => console.log(err))
  }, [serviceTypes])

  return (
    <div className="App">
      {
        (info) && (
          <Navbar info={info}/>
        )
      }
      <div className="min-h-[100vh] mx-[10px] md:mx-[20px]">
        <ScrollToTop />
        {
          (info.banner) && (
            <TopCarousel data={info.banner}/>
          )
        }
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tintuc" element={<News />} />
          <Route path="/tintuc/:id" element={<NewsDetail />} />
        </Routes>
        {
          (info) && (
            <Contact info={info}/>
          )
        }
      </div>
      {
        (info) && (
          <>
            <FloatContactButton info={info}/>
            <Footer info={info}/>
          </>
        )
      }
    </div>
  );
}

export default App;
