import { create } from 'zustand';

interface ServicesStore {
  services: any;
  servicesByType: any;
  serviceTypes: any;
  detail: any;
  setServiceTypes: (data:any) => void;
  setServicesByType: (data:any) => void;
  setServices: (data:any) => void;
  viewDetail: (id:number) => void;
}

export const useServices = create<ServicesStore>((set, get) => ({
  services: [],
  detail: {},
  serviceTypes: [],
  servicesByType:[],
  setServices: (data) => set({ services: data }),
  setServiceTypes: (data) => set({ serviceTypes: data }),
  setServicesByType: (data) => set({ servicesByType: data }),
  viewDetail: (id) => {
    const data:any = get();
    const { servicesbyType } = data;
    if(servicesbyType){
      const service = servicesbyType.find((item:any) => item.id == id);
      set({ detail: service });
    }
  }
}))
