import { FloatButton } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

interface Props{
  info:any;
}

export function FloatContactButton(props:Props) {
  const { info } = props;
  return (
    <>
        <FloatButton className="w-[60px] h-[60px] z-[2000]" shape="circle" icon={<img src="https://page.widget.zalo.me/static/images/2.0/Logo.svg"/>} onClick={() => window.location.href="https://zalo.me/" + info.phone} style={{bottom:120}}/>
        <FloatButton className="w-[60px] h-[60px] z-[2000]" shape="circle" icon={<PhoneOutlined />} tooltip={info.phone} onClick={() => window.location.href="tel:" + info.phone} />
    </>
  );
}
