import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useNavStore } from '../zustand/Nav';

interface Props{
  info:any;
}

export function Navbar(props:Props) {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const activePage = useNavStore((state:any) => state.activePage);
  const setActivePage = useNavStore((state:any) => state.setActivePage);
  const { info } = props;

  return (
    <nav className="flex items-center justify-evenly sticky top-0 h-[80px] z-[1000] bg-[#FFF]" style={{boxShadow: '0 2px 4px 0 rgba(0,0,0,.1)'}}>
      <div className="absolute left-10 text-[25px] lg:hidden" onClick={() => setOpenDrawer(true)}>
        <MenuOutlined/>
      </div>
      <Link to="/" className="h-fit w-[150px]" onClick={() => setActivePage(0)}>
        <img className="w-full h-full object-contain" src={info.logo}/>
      </Link>
      <div className="hidden space-x-4 uppercase lg:flex lg:space-x-10 text-[14px]">
        <Link to="/" onClick={() => setActivePage(3)} className={'hover:text-[#ff0000] ' + (activePage==3?'text-[#ff0000]':'text-[#000]')}>Trang chủ</Link>
        <Link to="/tintuc" onClick={() => setActivePage(2)} className={'hover:text-[#ff0000] ' + (activePage==2?'text-[#ff0000]':'text-[#000]')}>Tin tức</Link>
      </div>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} placement="left" className="lg:hidden">
        <div className="flex flex-col items-center space-y-8 uppercase text-[16px]">
          <a href="/" className="h-fit w-[150px]" onClick={() => setOpenDrawer(false)}>
            <img className="w-full h-full object-contain" src={info.logo}/>
          </a>
          <Link to="/" onClick={() => setOpenDrawer(false)}>Trang chủ</Link>
          <Link to="/tintuc" onClick={() => setOpenDrawer(false)}>tin tức</Link>
          <a href={'tel:' + info.phone} className="rounded-[20px] bg-[#FF0000] py-4 px-8 text-[#FFF]">
            {info.phone}
          </a>
        </div>
      </Drawer>
    </nav>
  )
}
