import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import config from '../config.json';

const { serverhost } = config;

const isEmptyObj = (obj:any) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

export function NewsDetail(){
  const { id }:any = useParams();
  const [news, setNews] = useState<any>([]);

  var formData = new FormData();
  formData.append('id', id);

  useEffect(() => {
    fetch(serverhost + '/news-detail.php', {method: 'POST', body: formData})
    .then(res => res.json())
    .then(data => {
      data.content = (data.content) ? data.content.split(/\r?\n|\r|\n/g) : [];
      setNews(data);
    })
    .catch(err => console.log(err))
  }, [])

  return(
    <div className="mt-10">
      <Breadcrumb
        items={[
          {
            title: <Link to="/">Trang chủ</Link>,
          },
          {
            title: <Link to="/news">Tin tức - sự kiện</Link>,
          }
        ]}
      />
      {
        (isEmptyObj(news) == false) && (
          <>
          <h2 className="text-left">{news.title}</h2>
          <span className="text-[#666]">{news.created_at}</span>
          <div className="whitespace-normal text-justify mt-10 leading-8">
            {
              news.content.map((line:any) => {
                return(
                  <div>{line}</div>
                )
              })
            }
          </div>
          <div className="mt-10"><img src={news.image}/></div>
          </>
        )
      }
    </div>
  )
}
