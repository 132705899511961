import { numberWithCommas } from '../utilities/NumberWithCommas';

interface Props{
  price: number;
}

export function Price(props:Props){
  const { price } = props;
  if(price == 0){
    return (
      <span>-</span>
    );
  }
  else{
    return (
      <span>{numberWithCommas(price)}<sup>đ</sup></span>
    );
  }
}
